export default ({ request }) => ({
  /**
   *  支付详情 -- 支付课程详情
   * @param { Object } data
   */
  Detail(data) {
    return request({
      url: '/portal/course/detail',
      method: 'get',
      params: data,
    });
  },
  /**
   * 获取微信二维码
   * @param {Object} data
   */
  wx(data) {
    return request({
      url: '/order/wx',
      method: 'post',
      data,
    });
  },
  /**
   * 获取支付宝支付
   * @param { Object } data
   */
  AliPay(data) {
    return request({
      url: '/order/ali',
      method: 'post',
      data,
    });
  },
  /**
   * 支付状态订单详情
   * @param { Object } data
   */
  state(data) {
    return request({
      url: '/order/detail',
      method: 'get',
      params: data,
    });
  },
  /**
   * 报名
   * @param { Object }data
   */
  join(data) {
    return request({
      url: '/order/course/sign',
      method: 'post',
      data,
    });
  },
  /**
   * 获取个人信息
   * @param { header } token
   */
  information() {
    return request({
      url: '/psl/user/info/get',
      method: 'post',
    });
  },
  /**
   * 个人中心 -- 我的订单 -- 消息中心
   * @param { Object } data
   */
  message(data) {
    return request({
      url: '/message/center/list',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料 -- 验证新手机号验证码
   * @param { Object } data
   */
  cheackNewPhone3(data) {
    return request({
      url: '/psl/user/phone/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 上传头像
   *@param { FormData } data
   */
  loadHeadImg(data) {
    return request({
      url: '/psl/user/url/modify',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料--修改名称
   * @param { Object }data
   */
  name(data) {
    return request({
      url: '/psl/user/info/modifyNew',
      method: 'post',
      data,
    });
  },
  /**
   * 个人中心--密码设置
   * @param { Object }data
   */
  repassword(data) {
    return request({
      url: '/psl/user/password/modify',
      method: 'post',
      data,
    });
  },
  phone() {
    return request({
      url: '/psl/user/code/old/phone',
      method: 'post',
    });
  },
  /**
   * 个人资料 --判断旧验证码手机号
   * @param { Object } data
   */
  cheackPhone(data) {
    return request({
      url: '/psl/user/code/old/phone/check',
      method: 'post',
      data,
    });
  },
  /**
   * 个人资料 --新手机号发送验证码
   * @param { Object } data
   */
  getNewPhone(data) {
    return request({
      url: '/psl/user/code/modify/phone',
      method: 'post',
      data,
    });
  },
  /**
   * 取消订单
   * @param { Object } data
   */
  cancel(data) {
    return request({
      url: '/customer/course/cancelOrder',
      method: 'put',
      data,
    });
  },
});
