<template>
  <div class="Home">
    <on-header></on-header>
    <router-view></router-view>
    <on-footer></on-footer>
  </div>
</template>

<script>
import OnHeader from '../components/Header.vue';
import OnFooter from '../components/Footer.vue';

export default {
  components: {
    OnHeader,
    OnFooter,
  },
};
</script>

<style lang="scss" scoped>
.Home {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
}
</style>
