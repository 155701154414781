<template>
  <div id="Footer">
    <div class="footer-box">
      <footer class="footer">
        <div class="left">
          <h2>关于印工社</h2>
          <div style="color:#f7b515">———</div>
          <br />
          <p>
            印工社由印刷行业资深专家组织发起，旨在打造专属于印刷行业的“互联网+中小企业”云服务平台。通过为中小
            印刷企业提供CRM、PLM、MES、WMS、TQM等信息化解决方案，帮助企业实现低成本数字化改造，为智能打造好坚实的基础。
          </p>
          <br />
          <br />
          <h3>咨询热线</h3>
          <h3 style="color:#f7b515">400-900-2025</h3>
          <br />
          <br />
          <h3>邮箱信息</h3>
          <h3>ygs@cpct.net.cn</h3>
          <div class="imgCode">
            <img src="../assets/img/fae9d32a8d8a367d81a90edc6dd1911.jpg" alt="" />
          </div>
        </div>
        <div class="right">
          <h2>产品与服务</h2>
          <div style="color:#f7b515">———</div>
          <br />
          <ul>
            <li>
              <a :href="`${VUE_APP_USERCONSOLE}PrintSteward`">印刷管家</a>
            </li>

            <li>
              <a :href="`${VUE_APP_USERCONSOLE}Course`">学习园地</a>
            </li>

            <li>
              <a :href="`${VUE_APP_HOME}information`">新闻资讯</a>
            </li>
            <li>
              <a :href="`${VUE_APP_HOME}about`">关于我们</a>
            </li>
          </ul>
        </div>
      </footer>
      <div class="line"></div>
      <div class="end">
        Copyright&copy; &nbsp;2019-{{ DataYears }} &nbsp;印工社 （青岛）互联网网络科技有限公司 All
        Rights Reserved&nbsp; | &nbsp;鲁ICP备12040237号-2&nbsp; <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37021402002207\" target="_banck">鲁公网安备 37021402002207号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE, // 控制台项目地址
      VUE_APP_HOME: process.env.VUE_APP_HOME, // portal主站地址
    };
  },
  computed: {
    DataYears() {
      const date = new Date();
      const years = date.getFullYear();
      return years;
    },
  },
  created() {},

  methods: {
    // getDateTime:{
    //   const
    // }
  },
};
</script>

<style lang="scss" scoped>
#Footer {
  background: #333333;
}
h2 {
  color: #ffffff;
}
h3 {
  color: #ffffff;
}
.footer-box {
  width: 1200px;
  height: 500px;
  margin: auto;
}
.footer {
  display: flex;
  justify-content: space-between;
  .left {
    width: 700px;
    height: 350px;
    color: #ffffff;
    margin-top: 60px;
    position: relative;
    .imgCode {
      position: absolute;
      right: 0;
      bottom: 40px;
      img {
        width: 120px;
        height: 120px;
        border: 1px solid #f7b515;
      }
    }
  }
  .right {
    width: 400px;
    height: 350px;
    margin-top: 60px;
    color: #ffffff;
    ul li {
      height: 50px;
      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}
.line {
  width: 1200px;
  height: 1px;
  background: #ffffff;
  margin: auto;
  color: rgb(178, 179, 181);
}
.end {
  text-align: center;
  margin-top: 30px;
  color: rgb(157, 170, 170);
}
.end a {
  color: rgb(178, 179, 181);
  text-decoration: none
}
</style>
