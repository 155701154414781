import Vue from 'vue';
import ElementUI from 'element-ui';
import clipboard from 'clipboard';
import App from './App.vue';
import router from '@/router/index.js';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/style.scss'; // 自定义样式
import '@/icons';

Vue.prototype.clipboard = clipboard;
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
