import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/', //课程详情
        name: 'CourseDetails',
        component: () => import('../views/Buy/CourseDetails')
      },
      {
        path: '/Free',
        name: 'Free',
        component: () => import('../views/Buy/Free')
      },
      {
        path: '/CourseDetails', //课程详情
        name: 'CourseDetails',
        component: () => import('../views/Buy/CourseDetails')
      },
      {
        path: '/BeforePay', //去付款
        name: 'BeforePay',
        component: () => import('../views/Buy/BeforePay')
      },
      {
        path: '/Bought', //已购买
        name: 'Bought',
        component: () => import('../views/Buy/Bought')
      },
      {
        path: '/Pay', //微信或支付宝支付
        name: 'Pay',
        component: () => import('../views/Buy/Pay')
      },
      {
        path: '/PayAfter', //付款成功
        name: 'PayAfter',
        component: () => import('../views/Buy/PayAfter')
      },
      {
        path: '/WaitPay', //待付款
        name: 'WaitPay',
        component: () => import('../views/Buy/WaitPay')
      },
      {
        path: '/SuccessfulPay', //成功购买
        name: 'SuccessfulPay',
        component: () => import('../views/Buy/SuccessfulPay')
      },
      {
        path: '/CancelPay', //取消支付
        name: 'CancelPay',
        component: () => import('../views/Buy/CancelPay')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
