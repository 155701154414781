import Vue from 'vue';
import Vuex from 'vuex';
// import Cookies from 'js-cookie'
import API from '@/api';

Vue.use(Vuex);

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
  state: {
    userInfo: [],
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
  },
  actions: {
    // 获取个人信息
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        API.information()
          .then((res) => {
            if (res.message.success) {
              commit('SET_USERINFO', res.message.data);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出登录
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USERINFO', '');
        resolve();
      });
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
  },
});
